import React from 'react';
import Layout from '@layouts';
import { Banners, SEO, PrivacyPolicy } from '@components';

const { Banner } = Banners;
export default function Index() {
  return (
    <Layout bannerChildren={<Banner title='Privacy Policy' description='Updated on 2022-04-30' />}>
      <SEO title='TAIK - PRIVACY POLICY' description="The Africa I Know, Inc' Privacy Policy." />
      <PrivacyPolicy />
    </Layout>
  );
}
